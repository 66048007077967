import { gsap } from "gsap";

const leave = () => {
    var tl = gsap.timeline();

    tl.to(".transition li", {
        duration: 0.3,
        scaleY: 1,
        transformOrigin: "bottom left",
        stagger: 0.2,
    });
}

const enter = () => {
    var tl = gsap.timeline();

    tl.to(".transition li", {
        duration: 0.3,
        scaleY: 0,
        transformOrigin: "bottom left",
        stagger: 0.1,
        delay: 0.1,
    });
}

export default {leave, enter}

