import 'assets/styles/app.scss';
import 'bootstrap';
import transitions from './pagetransition';
import delay from "./helpers/delay";


barba.init({
	prevent: ({ el }) => el.classList && el.classList.contains('barba-ignore'),
	transitions: [{
		name: 'default-transition',
		async leave() { // todo delay not as promise
			document.querySelector('body').classList.add('transitioning')
			const done = this.async();
			transitions.leave();
			await delay(1500);
			done();
		},
		async enter() {
			bootstrap();
			$(window).scrollTop(0);
			transitions.enter();

			document.querySelector('body').classList.remove('transitioning','is-open-menu')
		},
	}]
});


const bootstrap = async() => {
	const loadedComponents = [];

	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`../components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.log(componentName);
				console.error( 'Loading error: ', error );
			}
		}
	}
}

$( '.js-contact-popup' ).on('click',  (e) => {
	e.preventDefault();
	$( 'body' ).toggleClass( 'open-contact-popup' );
} );
// barba.hooks.after(bootstrap());

document.addEventListener( 'DOMContentLoaded', bootstrap() );
